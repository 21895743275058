import './App.css';
import '@fontsource/ubuntu';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import {
  FormGroup,
  TextField,
  Button,
  Paper,
  CircularProgress,
  Alert,
  Checkbox,
  FormControlLabel,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import React from 'react';
// import { render } from '@testing-library/react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Cookies from 'universal-cookie';

import i18n_hub from './i18n.json';

const cookies = new Cookies();
const formSubmittedSuccessCookieName = 'vyum_form_submitted_successfully';
const formSubmittedSuccessCookieValue = () =>
  cookies.get(formSubmittedSuccessCookieName);

const languages = [
  // 'Arabic',
  'Chinese', // 简化字
  'English',
  'French', // Français
  'German', // Deutsch
  'Russian', // Русский язык
  'Spanish', // Español
];

// const languages_key_map = {
//   Arabic: { key: 'ar' },
//   Chinese: { key: 'ch' },
//   English: { key: 'en' },
//   French: { key: 'fr' },
//   German: { key: 'ge' },
//   Russian: { key: 'ru' },
//   Spanish: { key: 'sp' },
// };

const languages_nativeSpelling_map = {
  // Arabic: { nativeSpelling: 'ar' },
  Chinese: { nativeSpelling: '简化字' },
  English: { nativeSpelling: 'English' },
  French: { nativeSpelling: 'Français' },
  German: { nativeSpelling: 'Deutsch' },
  Russian: { nativeSpelling: 'Русский язык' },
  Spanish: { nativeSpelling: 'Español' },
};

// Followed this to
// setup state handlers - https://reactjs.org/docs/forms.html#gatsby-focus-wrapper, https://reactjs.org/docs/hooks-effect.html
// to use style lib - https://mui.com/material-ui/react-text-field/#validation
// form submit to api - https://www.techomoro.com/submit-a-form-data-to-rest-api-in-a-react-app/
// country flag unicodes - https://emojipedia.org/flag-united-states/, or not?? https://weglot.com/blog/flags-to-represent-languages/
// translations - https://www.deepl.com/translator

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      fullNameIsValid: null,
      email: '',
      emailIsValid: null,
      submitButtonDisabled: true,
      formSubmitted: false,
      formSubmittedSuccess: false,
      formAlreadySubmittedSuccessfully: false,
      menuAnchorEl: null,
      menuIsOpen: false,
      activeLanguage: 'English',
      activeLanguageKey: 'en',
      // formSubmittedFailed: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.validateFullName = this.validateFullName.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMenuOnClick = this.handleMenuOnClick.bind(this);
    this.translator = this.translator.bind(this);
  }

  componentDidMount() {
    if (formSubmittedSuccessCookieValue()) {
      this.setState(() => ({
        formAlreadySubmittedSuccessfully: true,
      }));
    }
  }

  componentDidUpdate() {
    const { fullNameIsValid, emailIsValid, submitButtonDisabled } = this.state;

    if (fullNameIsValid && emailIsValid && submitButtonDisabled) {
      this.setState(() => ({
        submitButtonDisabled: !submitButtonDisabled,
      }));
    }

    if ((!fullNameIsValid || !emailIsValid) && !submitButtonDisabled) {
      this.setState(() => ({
        submitButtonDisabled: !submitButtonDisabled,
      }));
    }
  }

  handleChange(event) {
    // this.setState({ value: event.target.value });
    // this.setState({ [event.target.name]: event.target.value });

    this.setState(() => ({
      [event.target.name]: event.target.value,
    }));

    // console.log(this.state);
  }

  validateFullName(event) {
    const regex = new RegExp('\\w{2,}\\s\\w*');

    if (this.state.fullName.length) {
      this.setState(() => ({
        fullNameIsValid: regex.test(event.target.value),
      }));
    } else {
      this.setState(() => ({
        fullNameIsValid: null,
      }));
    }
  }

  validateEmail(event) {
    // https://www.w3resource.com/javascript/form/email-validation.php
    // const regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    const regex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

    if (this.state.email.length) {
      this.setState(() => ({
        emailIsValid: regex.test(event.target.value),
      }));
    } else {
      this.setState(() => ({
        emailIsValid: null,
      }));
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState(() => ({
      formSubmitted: true,
    }));

    try {
      let res = await fetch(`https://api.vyum.ai/api/send-email`, {
        // let res = await fetch(`http://localhost:3001/api/send-email`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          // Host: ' api.vyum.ai',
          'x-api-key':
            '008188175e660dbeb07737cf8c3dc576:1364cb55eca25adb988944fe786845fe2341a80b9676d06dc1e5dda0b1bffba2d7691342a55c390dfc39d6a025e14968',
        },
        method: 'POST',
        body: JSON.stringify({
          fullName: this.state.fullName,
          email: this.state.email,
        }),
        // body: JSON.stringify({}), // uncomment to test 400
      });

      let resJson = await res.json();

      console.log('=========');
      console.log(resJson);

      if (res.status === 200) {
        setTimeout(() => {
          console.log('ok', resJson);
          this.setState(() => ({
            formSubmittedSuccess: true,
          }));

          cookies.set(
            formSubmittedSuccessCookieName,
            new Date().toLocaleString(),
            { path: '/' },
          );
        }, 1000);
      } else {
        console.log('not ok');
        // this.setState(() => ({
        //   formSubmittedFailed: true,
        //   formSubmitted: false,
        // }));
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleMenuOnClick(event) {
    // console.log('=======');
    // console.log(event.target);
    // console.log(event.target.getAttribute('data-language'));
    // console.log('=======');
    this.setState(() => ({
      menuAnchorEl: !this.state.menuIsOpen ? event.target : null,
      // menuAnchorEl: event.target,
      menuIsOpen: !this.state.menuIsOpen,
      activeLanguage: event.target.getAttribute('data-language')
        ? event.target.getAttribute('data-language')
        : this.state.activeLanguage,
      activeLanguageKey: event.target.getAttribute('data-language')
        ? event.target.getAttribute('data-language').slice(0, 2).toLowerCase()
        : this.state.activeLanguageKey,
    }));
  }

  translator(stringKey) {
    return i18n_hub[this.state.activeLanguageKey][stringKey];
  }

  render() {
    return (
      <div className="App">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar id="appBarMenu" position="static">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { sm: 'block' } }}>
                <span id="appBarMenuTitle">vyum</span>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { md: 'flex' } }}>
                <IconButton
                  id="appBarMenuLanguagesButton"
                  onClick={this.handleMenuOnClick}
                  aria-haspopup="true"
                  color="inherit">
                  {
                    languages_nativeSpelling_map[this.state.activeLanguage]
                      .nativeSpelling
                  }
                  {this.state.menuIsOpen ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                <Menu
                  id="appBarMenuLanguages"
                  anchorEl={() => {
                    return document.querySelector('#appBarMenuLanguagesButton');
                  }}
                  open={this.state.menuIsOpen}
                  onClose={this.handleMenuOnClick}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}>
                  {languages
                    .filter(
                      (language) => language !== this.state.activeLanguage,
                    )
                    .sort()
                    .map((x, i) => (
                      <MenuItem
                        onClick={this.handleMenuOnClick}
                        key={i}
                        data-language={x}>
                        {languages_nativeSpelling_map[x].nativeSpelling}
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        <header id="coming_soon" className="App-header">
          <Paper elevation={24} id="form">
            {this.state.formSubmittedSuccess && (
              <Alert severity="success">
                {this.translator('formSubmittedSuccess')}
              </Alert>
            )}
            {this.state.formSubmittedFailed && (
              <Alert severity="error">
                {this.translator('formSubmittedFailed')}
              </Alert>
            )}
            {this.state.formSubmitted && !this.state.formSubmittedSuccess && (
              <CircularProgress />
            )}
            {!this.state.formSubmitted && !this.state.formSubmittedSuccess && (
              <div>
                <Typography color="text.secondary" gutterBottom>
                  {this.translator('welcomeMessage')}
                </Typography>
                {this.state.formAlreadySubmittedSuccessfully && (
                  <Alert severity="info">
                    {this.translator('formAlreadySubmittedSuccessfully')}
                    <br />
                    {formSubmittedSuccessCookieValue()}
                  </Alert>
                )}
                {!this.state.formAlreadySubmittedSuccessfully && (
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label={this.translator('fullNameFieldPlaceHolder')}
                        variant="outlined"
                        margin="dense"
                        name="fullName"
                        color={
                          this.state.fullName.length > 0 &&
                          this.state.fullNameIsValid
                            ? 'success'
                            : ''
                        }
                        focused={
                          this.state.fullName.length > 0 &&
                          this.state.fullNameIsValid
                        }
                        value={this.state.fullName}
                        onChange={(event) => {
                          return this.handleChange(event);
                        }}
                        onBlur={(event) => {
                          return this.validateFullName(event);
                        }}
                        error={
                          (this.state.fullName.length > 0 &&
                            this.state.fullNameIsValid === false) ||
                          false
                        }
                        required
                      />
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label={this.translator('emailFieldPlaceholder')}
                        variant="outlined"
                        margin="dense"
                        name="email"
                        color={
                          this.state.email.length > 0 && this.state.emailIsValid
                            ? 'success'
                            : ''
                        }
                        focused={
                          this.state.email.length > 0 && this.state.emailIsValid
                        }
                        value={this.state.email}
                        onChange={(event) => {
                          return this.handleChange(event);
                        }}
                        onBlur={(event) => {
                          return this.validateEmail(event);
                        }}
                        error={
                          (this.state.email.length > 0 &&
                            this.state.emailIsValid === false) ||
                          false
                        }
                        required
                      />
                      <FormControlLabel
                        value="end"
                        control={<Checkbox />}
                        label={this.translator('checkboxLabel')}
                        labelPlacement="end"
                      />
                      <Button
                        variant="contained"
                        size="small"
                        margin="dense"
                        type="submit"
                        id="formSubmit"
                        disabled={this.state.submitButtonDisabled}>
                        {this.translator('submitButton')}
                      </Button>
                    </FormGroup>
                  </form>
                )}
              </div>
            )}
          </Paper>
        </header>
      </div>
    );
  }
}

export default App;
